import {Controller} from '@hotwired/stimulus';
import {getComponent} from '@symfony/ux-live-component';
import {initFlowbite} from 'flowbite';

export default class extends Controller {
    async initialize() {
        initFlowbite();

        this.component = await getComponent(this.element);
        this.component.on('render:finished', () => initFlowbite());
    }
}
